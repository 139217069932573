import Box from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import EllipsisText from "./EllipsisText";
import { useEffect, useState, useContext, ReactNode } from "react";
import InitialsIcon from "./InitialsIcon";
import GoldStarIcon from "/gold-star.svg";
import linkedinIcon from "/linkedin.svg";
import notesIndicatorSvg from "/notes-indicator.svg";
import { AlumProfileEvent } from "../lib/eventEnums";
import { buildUrl, trackGoogleAnalyticsEvent, Format } from "../lib/utils";
import { UserContext } from "../lib/context";
import { useTranslation } from "react-i18next";
import { Translation } from "../lib/constants";
import Typography from "@mui/material/Typography";
import { FormattedText, FormattedTextType } from "./FormattedText";
import Tooltip from "./Tooltip.tsx";
import theme from "./index.ts";
import { EmployeeOrgState } from "../models/filter.ts";
import { NoteDTO } from "../models/api/alum.ts";
import { Chip } from "@mui/material";
import { ShowAllNotesModal } from "../components/Notes/ShowAllNotesModal.tsx";

interface AvatarProps {
  firstName: string;
  lastName: string;
  email: string;
  location?: string | null;
  size: AvatarSize;
  numOfNotes?: number | null;
  notes?: NoteDTO[] | null;
  photoUrl?: string | null;
  linkedInUrl?: string | null;
  tag?: ReactNode;
  stackProps?: StackProps;
  isSubscribed?: boolean;
  isDisabled?: boolean;
  callToAction?: ReactNode;
  alumState?: EmployeeOrgState | null;
  showAlumState?: boolean | null;
  terminationDescription?: string | null;
  onAvatarClick?: () => void;
  attributes?: ProfileAttributes | null;
}

export type ProfileAttributes = {
  wouldRehire?: boolean | null;
};

export default function Avatar(props: AvatarProps) {
  const { t } = useTranslation(Translation.alumProfile);
  const {
    firstName,
    lastName,
    email,
    location,
    size,
    numOfNotes,
    notes,
    photoUrl,
    linkedInUrl,
    alumState,
    showAlumState = true,
    terminationDescription,
    tag,
    isDisabled,
    stackProps,
    callToAction,
    onAvatarClick,
    attributes,
  } = props;
  const [imageError, setImageError] = useState(false);
  const [showAllNotesModal, setShowAllNotesModal] = useState(false);
  const [user] = useContext(UserContext);

  useEffect(() => {
    setImageError(false);
  }, [photoUrl]);

  const handleLinkedInClick = () => {
    if (user?.orgId) {
      trackGoogleAnalyticsEvent({
        event: AlumProfileEvent.ALUM_PROFILE_LINKEDIN,
        type: "linkedin_click",
        org: user?.orgName,
      });
    }
  };

  let nameFontSize = "";
  let emailFontSize = "";
  let locationFontSize = "";
  let profileIconFontSize = "";
  let iconSize = "";
  let imageNameGap = "";

  switch (size) {
    case AvatarSize.SMALL:
      nameFontSize = "0.75rem";
      emailFontSize = "0.75rem";
      locationFontSize = "0.75rem";
      profileIconFontSize = "0.875rem";
      iconSize = "2.25rem";
      imageNameGap = "0.75rem";
      break;
    case AvatarSize.MEDIUM:
      nameFontSize = "0.875rem";
      emailFontSize = "0.875rem";
      locationFontSize = "0.875rem";
      profileIconFontSize = "0.875rem";
      iconSize = "2.5rem";
      imageNameGap = "0.75rem";
      break;
    case AvatarSize.LARGE:
      nameFontSize = "1.5rem";
      emailFontSize = "1rem";
      locationFontSize = "1rem";
      profileIconFontSize = "1.5rem";
      iconSize = "5rem";
      imageNameGap = "1rem";
      break;
  }

  return (
    <>
      <Stack
        onClick={() => onAvatarClick && onAvatarClick()}
        id="avatar"
        flexDirection="row"
        gap={imageNameGap}
        alignItems="center"
        {...stackProps}
      >
        <Box flexShrink={0} flexGrow={0}>
          {photoUrl && !imageError ? (
            <Box
              width={iconSize}
              height={iconSize}
              borderRadius="50%"
              overflow="hidden"
            >
              <Box
                component="img"
                alt="profile-photo"
                src={photoUrl}
                width="100%"
                height="100%"
                onError={() => setImageError(true)}
              />
            </Box>
          ) : (
            <InitialsIcon
              firstName={firstName}
              lastName={lastName}
              size={iconSize}
              fontSize={profileIconFontSize}
            />
          )}
        </Box>
        <Stack
          width={`calc(100% - ${iconSize} - ${imageNameGap})`}
          flexShrink={0}
          flexGrow={0}
          gap="1.5rem"
        >
          <Stack gap="0.25rem">
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                gap="0.25rem"
                color={(theme) => {
                  if (isDisabled) {
                    return theme.palette.grey[400];
                  }
                  return theme.palette.grey[800];
                }}
              >
                <EllipsisText
                  fontSize={nameFontSize}
                  fontWeight={size === AvatarSize.LARGE ? 600 : 400}
                  color="inherit"
                  alignItems="center"
                  gap="0.5rem"
                >
                  {firstName} {lastName}
                </EllipsisText>
                {showAlumState && alumState === EmployeeOrgState.FAVORITE && (
                  <Tooltip title={t("alumState.favorite.favorited")}>
                    <Box
                      className="favoriteIcon"
                      component="img"
                      src={GoldStarIcon}
                      alt="favorite"
                      width="1rem"
                      height="1rem"
                    />
                  </Tooltip>
                )}
                {showAlumState && alumState === EmployeeOrgState.ARCHIVE && (
                  <Box
                    bgcolor={theme.palette.grey[500]}
                    borderRadius="0.375rem"
                  >
                    <FormattedText
                      props={{
                        value: t("alumState.archive.archived"),
                        type: FormattedTextType.PRIMARY,
                      }}
                      style={{
                        padding: "0.25rem 0.5rem",
                        color: theme.palette.common.white,
                      }}
                    />
                  </Box>
                )}
                {tag && <Box paddingRight="1.5rem">{tag}</Box>}
                {callToAction && <Box marginLeft="auto">{callToAction}</Box>}
              </Stack>
              <FormattedText
                props={{
                  value: email,
                  type: FormattedTextType.SECONDARY,
                }}
                style={{
                  fontSize: emailFontSize,
                  paddingRight: "1.5rem",
                  color: (theme) => {
                    if (isDisabled) {
                      return theme.palette.grey[400];
                    } else if (size === AvatarSize.LARGE) {
                      return theme.palette.grey[800];
                    }
                    return theme.palette.grey[500];
                  },
                }}
              />
            </Box>
            {numOfNotes != null && numOfNotes > 0 && (
              <Box
                id="notesIndicator"
                bgcolor={theme.palette.grey[50]}
                borderRadius="1rem"
                marginTop="0.125rem"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  height: "auto",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setShowAllNotesModal(true);
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="0.25rem"
                  sx={{ padding: "0.125rem 0.5rem 0.125rem 0.375rem;" }}
                >
                  <Box
                    component="img"
                    alt="notes-indicator"
                    src={notesIndicatorSvg}
                    width="12px"
                    height="12px"
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      paddingBottom: "0rem",
                      color: theme.palette.grey[800],
                      fontSize: "0.75rem",
                      fontWeight: 400,
                      paddingTop: "0rem",
                    }}
                  >
                    {numOfNotes}{" "}
                    {numOfNotes > 1
                      ? t("notes.quantity.notes")
                      : t("notes.quantity.note")}
                  </Typography>
                </Stack>
              </Box>
            )}
            {location && (
              <FormattedText
                props={{
                  value: Format.location(location),
                  type: FormattedTextType.SECONDARY,
                }}
                style={{
                  fontSize: locationFontSize,
                  paddingRight: "1.5rem",
                  color: (theme) => {
                    return theme.palette.grey[500];
                  },
                }}
              />
            )}
            {attributes && (
              <div id="avatar-attributes">
                {Object.entries(attributes).map(([key, value], ind) => {
                  return (
                    <Chip
                      key={ind}
                      size="small"
                      sx={(theme) => ({
                        width: "fit-content",
                        marginTop: "0.375rem",
                        marginRight: "0.375rem",
                        borderRadius: "0.4rem",
                        p: "0.8rem 0.2rem",
                        borderColor: theme.palette.grey[200],
                        backgroundColor: theme.palette.grey[50],
                        color: theme.palette.grey[800],
                      })}
                      label={`${t(`attributes.${key}.label`)}: ${t(
                        `attributes.${key}.${value}`,
                      )}`}
                      variant="outlined"
                    />
                  );
                })}
              </div>
            )}
            {terminationDescription && (
              <Stack
                direction="row"
                gap="4px"
                alignItems="center"
                paddingX="6px"
                sx={{
                  backgroundColor: theme.palette.grey[50],
                  borderRadius: "6px",
                  border: `1px solid ${theme.palette.grey[200]}`,
                  padding: "0.125rem 0.375rem",
                  width: "max-content",
                  marginTop: "0.25rem",
                }}
              >
                <Typography
                  color={theme.palette.grey[800]}
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                >
                  {t("departureReason")}: {terminationDescription}
                </Typography>
              </Stack>
            )}
            <></>
          </Stack>
          {linkedInUrl ? (
            <Box
              component="a"
              href={buildUrl(linkedInUrl)}
              target="_blank"
              onClick={handleLinkedInClick}
              width="2rem"
              height="2rem"
            >
              <Box
                component="img"
                src={linkedinIcon}
                width="2rem"
                height="2rem"
              />
            </Box>
          ) : null}
        </Stack>
      </Stack>

      <ShowAllNotesModal
        open={showAllNotesModal}
        notes={notes ?? []}
        onClose={() => setShowAllNotesModal(false)}
        onDelete={() => {  }}
      />
    </>
  );
}

export enum AvatarSize {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
}
