import Box from "@mui/material/Box";
import Breadcrumbs from "../../components/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  SyntheticEvent,
} from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import {
  getAllJobAlumniMatchesFile,
  getJobAlumniMatches,
  jamBookmark,
  jamMatch,
  jamRemoveBookmark,
  jamUnmatch,
} from "../../api/job";
import SearchBar from "../../components/Searchbar";
import Sort from "../../components/Sort";
import {
  FlagsContext,
  UserConfigContext,
  UserContext,
} from "../../lib/context";
import {
  capitalize,
  Format,
  loadRoleViewAlumniMatchesSortFromStorage,
  PAGE_SIZE,
  setRoleViewAlumniMatchesSortInStorage,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import {
  AlumniMatchesSortOptionEnum,
  JobAlumniMatchDTO,
  JobDashboardDTO,
  MatchedApplicantStateEnum,
  ListJobAlumniMatchDTO,
} from "../../models/api/job";
import { JobStatusEnum } from "../../models/filter.ts";
import theme from "../../theme";
import Button from "../../theme/Button";
import externalLink from "/link-external-primary.svg";
import exportIcon from "/share-01.svg";
import editIcon from "/edit-02.svg";
import RolesViewOverview from "./RolesViewOverview";
import RolesViewTable from "./RolesViewTable";
import { INITIAL_FETCH_SIZE, RouteId, Translation } from "../../lib/constants";
import { SearchbarContextType } from "../../models/api/common";
import CenterModal from "../../components/CenterModal";
import { Receiver } from "../../components/SendEmailModal";
import Loading from "../../components/Loading";
import { GAEvent, RoleViewEvent, SendMessageEvent } from "../../lib/eventEnums";
import { toast } from "react-toastify";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer.tsx";
import SendEmailModal from "../../components/SendEmailModal/index.tsx";
import { EmailTemplateTypeEnum } from "../../models/api/resource.ts";
import { RolesViewData } from "../../route.tsx";
import { useTableContext } from "../../components/TableView";
import {
  FormattedText,
  FormattedTextType,
} from "../../theme/FormattedText.tsx";
import Menu from "../../components/OptionsMenu";
import Tooltip from "../../theme/Tooltip.tsx";
import MailIcon from "/mail.svg";
import BookmarkIcon from "/bookmark.svg";
import RemoveBookmarkIcon from "/bookmark-minus.svg";
import UserXIcon from "/user-x-01.svg";
import { MatchedApplicantState } from "../../models/job.ts";
import ConfirmActionModal from "../../components/ConfirmActionModal/index.tsx";
import InfoToastContainer from "../../components/Toastify/InfoToastContainer.tsx";
import { Tabs, Tab, Snackbar, Fade } from "@mui/material";
import EmptyList from "../../components/EmptyList";
import UserXLargeIcon from "/user-x-large.svg";
import DotsVertical from "/dots-vertical-dark.svg";
import StarIcon from "/star-large.svg";
import BookmarkDark from "/bookmark-dark.svg";
import Users from "/users.svg";
import FeedbackIcon from "/feedback-thumbs.svg";
import RequestFeedbackModal from "../../components/SendEmailModal/RequestFeedbackModal.tsx";
import { IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface ActionPayload {
  jobAlumniMatchesToAffect: JobAlumniMatchDTO[];
  isBulkAction: boolean;
}

export default function RolesView({ baseRoute }: { baseRoute: string }) {
  const { t } = useTranslation(Translation.rolesView);
  const { jobId } = useParams();
  const navigate = useNavigate();
  const jobData = useRouteLoaderData(
    baseRoute === RouteId.alumDatabase
      ? RouteId.alumDatabaseRolesView
      : RouteId.rolesDatabaseRolesView,
  ) as RolesViewData;
  const [job, setJob] = useState<JobDashboardDTO>(jobData.job);
  const [numAlumSelected, setNumAlumSelected] = useState(0);
  const [sendEmailModalOpen, setSendEmailModalOpen] =
    useState<EmailTemplateTypeEnum | null>(null);
  const [user] = useContext(UserContext);
  const orgId = user?.orgId ?? "";
  const [userConfig] = useContext(UserConfigContext);
  const [flags] = useContext(FlagsContext);
  const [downloadToast, setDownloadToast] = useState<{
    open: boolean;
    type: "loading" | "failed";
  }>({
    open: false,
    type: "loading",
  });

  const [isLoadingJobMatches, setIsLoadingJobMatches] = useState(false);
  const [isLoadingUnmatchedJobMatches, setIsLoadingUnmatchedJobMatches] =
    useState(false);
  const [isLoadingBookmarkedJobMatches, setIsLoadingBookmarkedJobMatches] =
    useState(false);

  const viewJobDisabled = job?.status !== JobStatusEnum.OPEN;
  const isAutomationEnabled =
    userConfig?.hasAutomatedJobMatchingEnabled() ?? false;
  const [selectedOption, setSelectedOption] = useState<string>(
    t(loadRoleViewAlumniMatchesSortFromStorage()),
  );
  const [nextMatchingRun, setNextMatchingRun] = useState<string>(
    new Date().toString(),
  );
  const [confirmUnmatchModalOpen, setConfirmUnmatchModalOpen] = useState(false);
  const toastContainerIds = {
    info: "roles-view-info",
    success: "roles-view-success",
  };
  const [tabValue, setTabValue] = useState(0);
  const [unmatchPayload, setUnmatchPayload] = useState<ActionPayload | null>(
    null,
  );

  const showApprovalEmailAction = () => {
    return flags?.includes("HM_APPROVAL") ?? false;
  };

  const {
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    firstLoadTriggered,
    setFirstLoadTriggered,
    jobMatches,
    setJobMatches,
    selectedIds,
    setSelectedIds,
    lastJobId,
    setLastJobId,
    singleEmailReceiver,
    setSingleEmailReceiver,
    totalAlumniJobMatches,
    setTotalAlumniJobMatches,
    totalBookmarkedJobMatches,
    setTotalBookmarkedJobMatches,
    totalUnmatchedJobMatches,
    setTotalUnmatchedJobMatches,
    bookmarkedJobMatches,
    setBookmarkedJobMatches,
    unmatchedJobMatches,
    setUnmatchedJobMatches,
  } = useTableContext();

  useEffect(() => {
    const nextRun = new Date();
    if (nextRun.getHours() >= 4) {
      nextRun.setDate(nextRun.getDate() + 1);
    }
    nextRun.setHours(4);
    nextRun.setMinutes(0);
    setNextMatchingRun(nextRun.toString());
  }, []);

  // If this is a different job compared to the last time we visited roles view, we want to treat
  // this like a new route and reset the page number and search term.
  useEffect(() => {
    if (jobId && lastJobId !== jobId) {
      setPage(0);
      setSearchTerm("");
      setLastJobId(jobId);
    }
  }, [jobId, lastJobId, setLastJobId, setPage, setSearchTerm]);

  const prevValues = useRef({ selectedOption, searchTerm }).current;

  const fetchJobMatches = useCallback(
    async (
      state: MatchedApplicantStateEnum | undefined,
      jobId: string,
      setLoading = true,
    ) => {
      if (setLoading) {
        if (state === MatchedApplicantStateEnum.BOOKMARK) {
          setIsLoadingBookmarkedJobMatches(true);
        } else if (state === MatchedApplicantStateEnum.UNMATCH) {
          setIsLoadingUnmatchedJobMatches(true);
        } else {
          setIsLoadingJobMatches(true);
        }
      }

      try {
        const getSortOptionsDesc = (selectedOption: string) => {
          setRoleViewAlumniMatchesSortInStorage(selectedOption);
          switch (selectedOption) {
            case t("sortOption.strongestMatch"):
              return {
                sortOption: AlumniMatchesSortOptionEnum.MATCH_STRENGTH,
                sortDesc: true,
              };
            case t("sortOption.weakestMatch"):
              return {
                sortOption: AlumniMatchesSortOptionEnum.MATCH_STRENGTH,
                sortDesc: false,
              };
            case t("sortOption.newestStatusDate"):
              return {
                sortOption: AlumniMatchesSortOptionEnum.STATUS_MODIFIED_DATE,
                sortDesc: true,
              };
            case t("sortOption.oldestStatusDate"):
              return {
                sortOption: AlumniMatchesSortOptionEnum.STATUS_MODIFIED_DATE,
                sortDesc: false,
              };
            case t("sortOption.nameA-Z"):
              return {
                sortOption: AlumniMatchesSortOptionEnum.NAME,
                sortDesc: false,
              };
            case t("sortOption.nameZ-A"):
              return {
                sortOption: AlumniMatchesSortOptionEnum.NAME,
                sortDesc: true,
              };
            default:
              return {
                sortOption: AlumniMatchesSortOptionEnum.MATCH_STRENGTH,
                sortDesc: true,
              };
          }
        };

        const { sortDesc, sortOption } = getSortOptionsDesc(selectedOption);
        const response = await getJobAlumniMatches(
          orgId,
          jobId,
          page,
          INITIAL_FETCH_SIZE,
          sortOption,
          sortDesc,
          searchTerm,
          state,
        );
        switch (state) {
          case MatchedApplicantStateEnum.BOOKMARK:
            setBookmarkedJobMatches(response.alumni_matches);
            setTotalBookmarkedJobMatches(response.total);
            break;
          case MatchedApplicantStateEnum.UNMATCH:
            setUnmatchedJobMatches(response.alumni_matches);
            setTotalUnmatchedJobMatches(response.total);
            break;
          default:
            setJobMatches(response.alumni_matches);
            setTotalAlumniJobMatches(response.total);
        }
      } catch (error) {
        // TODO: BOO-1036
      } finally {
        if (setLoading) {
          if (state === MatchedApplicantStateEnum.BOOKMARK) {
            setIsLoadingBookmarkedJobMatches(false);
          } else if (state === MatchedApplicantStateEnum.UNMATCH) {
            setIsLoadingUnmatchedJobMatches(false);
          } else {
            setIsLoadingJobMatches(false);
          }
        }
      }
    },
    [
      selectedOption,
      orgId,
      page,
      searchTerm,
      t,
      setBookmarkedJobMatches,
      setTotalBookmarkedJobMatches,
      setUnmatchedJobMatches,
      setTotalUnmatchedJobMatches,
      setJobMatches,
      setTotalAlumniJobMatches,
    ],
  );

  // Fetch all the job matches at once (paginate on FE), re-runs whenever jobId or sortOption changes
  useEffect(() => {
    if (!jobId) return;

    if (
      prevValues.selectedOption !== selectedOption ||
      prevValues.searchTerm !== searchTerm ||
      !firstLoadTriggered
    ) {
      void fetchJobMatches(undefined, jobId);
      void fetchJobMatches(MatchedApplicantStateEnum.BOOKMARK, jobId);
      void fetchJobMatches(MatchedApplicantStateEnum.UNMATCH, jobId);

      setFirstLoadTriggered(true);
      prevValues.selectedOption = selectedOption;
      prevValues.searchTerm = searchTerm;
    }
  }, [
    fetchJobMatches,
    firstLoadTriggered,
    jobId,
    prevValues,
    searchTerm,
    selectedOption,
    setFirstLoadTriggered,
  ]);

  const sortOptions = [
    t("sortOption.strongestMatch"),
    t("sortOption.weakestMatch"),
    t("sortOption.newestStatusDate"),
    t("sortOption.oldestStatusDate"),
    t("sortOption.nameA-Z"),
    t("sortOption.nameZ-A"),
  ];
  const openSendEmailModal = (
    event: GAEvent,
    emailType: EmailTemplateTypeEnum,
  ) => {
    trackGoogleAnalyticsEvent({
      event: event,
      org: user?.orgName,
      customParameters: {
        num_selected: numAlumSelected,
        percent_selected: selectedIds.size / jobMatches.length,
      },
    });
    setSendEmailModalOpen(emailType);
  };

  const handleSendEmail = () => {
    setSendEmailModalOpen(null);
    toast.success(t("sendRole.successToast"), {
      containerId: toastContainerIds.success,
      toastId: "send-role",
    });
  };

  if (!jobId || !jobData || !user || !job) return <></>;

  const handleSearch = (searchValue: string) => {
    trackGoogleAnalyticsEvent({
      event: RoleViewEvent.ROLE_VIEW_SEARCH_QUERY,
      org: user?.orgName,
      type: searchValue,
    });
    setSearchTerm(searchValue);
  };

  const handleSortChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleViewJobPostingClicked = () => {
    if (viewJobDisabled) return;

    if (job?.job_posting_urls?.length ?? 0 > 0) {
      const firstUrl = job?.job_posting_urls?.[0]?.url;
      if (firstUrl) {
        window.open(firstUrl, "_blank");
      }
    } else if (job?.career_site_url) {
      window.open(job.career_site_url, "_blank");
    }
  };

  const trackSortAnalytics = (option: string) => {
    trackGoogleAnalyticsEvent({
      event: RoleViewEvent.ROLE_VIEW_SORT,
      org: user?.orgName,
      type: option,
    });
  };

  const onRowClick = (alumId: string) => {
    if (baseRoute === RouteId.rolesDatabase) {
      navigate(`alumni/${alumId}`);
    } else {
      navigate(`/alumni-database/${alumId}`);
    }
  };

  const handleAlumniSendMessage = (jobMatch: JobAlumniMatchDTO) => {
    openSendEmailModal(
      SendMessageEvent.SEND_MESSAGE_WINDOW,
      EmailTemplateTypeEnum.SEND_MESSAGE,
    );
    setSingleEmailReceiver({
      id: jobMatch.applicant_id,
      firstName: jobMatch.first_name,
      lastName: jobMatch.last_name,
      email: jobMatch.email,
      isSubscribed: jobMatch.is_subscribed,
    });
  };

  const handleAlumniSendRole = (jobMatch: JobAlumniMatchDTO) => {
    openSendEmailModal(
      RoleViewEvent.ROLE_VIEW_SEND_ROLE,
      EmailTemplateTypeEnum.SEND_ROLE,
    );

    setSingleEmailReceiver({
      id: jobMatch.applicant_id,
      firstName: jobMatch.first_name,
      lastName: jobMatch.last_name,
      email: jobMatch.email,
      isSubscribed: jobMatch.is_subscribed,
    });
  };

  const handleAlumniRequestFeedback = (jobMatch: JobAlumniMatchDTO) => {
    openSendEmailModal(
      RoleViewEvent.REQUEST_FEEDBACK,
      EmailTemplateTypeEnum.JOB_MATCH_APPROVAL,
    );

    setSingleEmailReceiver({
      id: jobMatch.applicant_id,
      firstName: jobMatch.first_name,
      lastName: jobMatch.last_name,
      email: jobMatch.email,
      isSubscribed: jobMatch.is_subscribed,
    });
  };

  const trackRoleViewActionEvent = (
    event: RoleViewEvent,
    jamIds: string,
    jobId: string,
  ) => {
    trackGoogleAnalyticsEvent({
      event: event,
      org: user?.orgName,
      customParameters: {
        jam_ids: jamIds,
        job_id: jobId,
      },
    });
  };

  const handleAlumniBookmark = async (
    jamsToBookmark: JobAlumniMatchDTO[],
    isBulkAction = false,
  ) => {
    try {
      const numUnbookmarkedAlumni = jamsToBookmark.filter(
        (jam) => jam.state !== MatchedApplicantState.BOOKMARK,
      ).length;

      await jamBookmark(
        orgId,
        jobId,
        jamsToBookmark.map((jam) => jam.applicant_id),
      );

      // update matches to reflect the bookmarked state since we don't refetch
      setJobMatches(
        jobMatches.map((jam) =>
          jamsToBookmark.some(
            (jamToBookmark) => jamToBookmark.applicant_id === jam.applicant_id,
          )
            ? { ...jam, state: MatchedApplicantState.BOOKMARK }
            : jam,
        ),
      );

      if (isBulkAction) {
        toast.success(
          t("toast.bookmark.success", {
            count: numUnbookmarkedAlumni,
          }),
          {
            containerId: toastContainerIds.success,
          },
        );
      }

      trackRoleViewActionEvent(
        RoleViewEvent.BOOKMARK,
        jamsToBookmark.map((jam) => jam.applicant_id).join(","),
        jobId,
      );
    } catch (error) {
      console.error(error);
    } finally {
      void fetchJobMatches(MatchedApplicantStateEnum.BOOKMARK, jobId, false);
    }
  };

  const handleAlumniRemoveBookmark = async (
    jamsToRemoveBookmark: JobAlumniMatchDTO[],
    isBulkAction = false,
  ) => {
    const currentJams = [...jobMatches];
    try {
      const numBookmarkedAlumni = jamsToRemoveBookmark.filter(
        (jam) => jam.state === MatchedApplicantState.BOOKMARK,
      ).length;
      setJobMatches(
        currentJams.map((jam) =>
          jamsToRemoveBookmark.some(
            (jamToUnbookmark) =>
              jamToUnbookmark.applicant_id === jam.applicant_id,
          )
            ? { ...jam, state: null }
            : jam,
        ),
      );

      await jamRemoveBookmark(
        orgId,
        jobId,
        jamsToRemoveBookmark.map((jam) => jam.applicant_id),
      );
      const currAlumSelected = new Set(
        Array.from(selectedIds).filter(
          (id) =>
            !jamsToRemoveBookmark.some(
              (jamToUnbookmark) => jamToUnbookmark.applicant_id === id,
            ),
        ),
      );
      setSelectedIds(currAlumSelected);
      setNumAlumSelected(currAlumSelected.size);
      if (isBulkAction) {
        toast.success(
          t("toast.removeBookmark.success", {
            count: numBookmarkedAlumni,
          }),
          {
            containerId: toastContainerIds.success,
          },
        );
      }

      trackRoleViewActionEvent(
        RoleViewEvent.BOOKMARK_REMOVE,
        jamsToRemoveBookmark.map((jam) => jam.applicant_id).join(","),
        jobId,
      );
    } catch (error) {
      setJobMatches(currentJams);
      console.error(error);
    } finally {
      void fetchJobMatches(MatchedApplicantStateEnum.BOOKMARK, jobId, false);
    }
  };

  const handleAlumniMatch = async (
    jamsToMatch: JobAlumniMatchDTO[],
    isBulkAction = false,
  ) => {
    try {
      const newSelectedIds = new Set(selectedIds);
      jamsToMatch.forEach((match) => newSelectedIds.delete(match.applicant_id));
      setSelectedIds(newSelectedIds);
      setNumAlumSelected(newSelectedIds.size);
      await jamMatch(
        orgId,
        jobId,
        jamsToMatch.map((jam) => jam.applicant_id),
      );

      setJob(
        (prev) =>
          prev && {
            ...prev,
            num_matches: prev.num_matches + jamsToMatch.length,
          },
      );
      const currAlumSelected = new Set(
        Array.from(selectedIds).filter(
          (id) =>
            !jamsToMatch.some((jamsToMatch) => jamsToMatch.applicant_id === id),
        ),
      );
      setSelectedIds(currAlumSelected);
      setNumAlumSelected(currAlumSelected.size);

      if (isBulkAction) {
        toast.success(
          t("unmatchModal.restoreMatchToast", {
            count: jamsToMatch.length,
          }),
          {
            containerId: toastContainerIds.success,
          },
        );
      }

      trackRoleViewActionEvent(
        RoleViewEvent.UNMATCH_REMOVE,
        jamsToMatch.map((jam) => jam.applicant_id).join(","),
        jobId,
      );
    } catch (error) {
      console.error(error);
    } finally {
      void fetchJobMatches(MatchedApplicantStateEnum.UNMATCH, jobId);
      void fetchJobMatches(undefined, jobId);
    }
  };

  const handleAlumniUnmatch = async (unmatchPayload: ActionPayload | null) => {
    if (!unmatchPayload) return;
    const { jobAlumniMatchesToAffect, isBulkAction } = unmatchPayload;
    try {
      const newSelectedIds = new Set(selectedIds);
      jobAlumniMatchesToAffect.forEach((match) =>
        newSelectedIds.delete(match.applicant_id),
      );
      setSelectedIds(newSelectedIds);
      setNumAlumSelected(newSelectedIds.size);
      await jamUnmatch(
        orgId,
        jobId,
        jobAlumniMatchesToAffect.map((jam) => jam.applicant_id),
      );

      setJob(
        (prev) =>
          prev && {
            ...prev,
            num_matches: prev.num_matches - jobAlumniMatchesToAffect.length,
          },
      );
      setUnmatchPayload(null);
      const currAlumSelected = new Set(
        Array.from(selectedIds).filter(
          (id) =>
            !jobAlumniMatchesToAffect.some(
              (jamsToUnmatch) => jamsToUnmatch.applicant_id === id,
            ),
        ),
      );
      setConfirmUnmatchModalOpen(false);
      setSelectedIds(currAlumSelected);
      setNumAlumSelected(currAlumSelected.size);

      if (isBulkAction) {
        toast.info(
          t("unmatchModal.unmatchToast", {
            count: jobAlumniMatchesToAffect.length,
          }),
          {
            containerId: toastContainerIds.info,
          },
        );
      }

      trackRoleViewActionEvent(
        RoleViewEvent.UNMATCH,
        jobAlumniMatchesToAffect.map((jam) => jam.applicant_id).join(","),
        jobId,
      );
    } catch (error) {
      console.error(error);
    } finally {
      void fetchJobMatches(MatchedApplicantStateEnum.UNMATCH, jobId);
      void fetchJobMatches(undefined, jobId);
    }
  };

  const handleConfirmUnmatchModalOpen = (
    matches: JobAlumniMatchDTO[],
    isBulkAction = false,
  ) => {
    setUnmatchPayload({
      jobAlumniMatchesToAffect: matches,
      isBulkAction: isBulkAction,
    });
    setConfirmUnmatchModalOpen(true);
  };

  const handleRestoreClick = () => {
    const selectedJobMatches = unmatchedJobMatches.filter(
      (match) =>
        selectedIds.has(match.applicant_id) &&
        match.state === MatchedApplicantState.UNMATCH,
    );
    void handleAlumniMatch(selectedJobMatches, true);
  };

  const generateBulkMenuOptions = () => {
    const selectedJobMatches = jobMatches.filter((match) =>
      selectedIds.has(match.applicant_id),
    );

    const options = [
      {
        disabled: false,
        className: "send-message-option",
        label: t("menuOptions.sendMessage.label"),
        handler: () =>
          openSendEmailModal(
            SendMessageEvent.SEND_MESSAGE_WINDOW,
            EmailTemplateTypeEnum.SEND_MESSAGE,
          ),
        hasDividerAbove: false,
        icon: <Box component="img" src={MailIcon} />,
      },
    ];

    if (showApprovalEmailAction()) {
      options.push({
        disabled: false,
        className: "request-feedback-option",
        label: t("menuOptions.requestFeedback.label"),
        hasDividerAbove: true,
        icon: <Box component="img" src={FeedbackIcon} />,
        handler: () =>
          openSendEmailModal(
            RoleViewEvent.REQUEST_FEEDBACK,
            EmailTemplateTypeEnum.JOB_MATCH_APPROVAL,
          ),
      });
    }

    const hasMatchedAlumni = selectedJobMatches.some(
      (jobMatch) => jobMatch.state !== MatchedApplicantState.UNMATCH,
    );
    const hasBookmarkedAlumni = selectedJobMatches.some(
      (jobMatch) => jobMatch.state === MatchedApplicantState.BOOKMARK,
    );
    const hasUnbookmarkedAlumni = selectedJobMatches.some(
      (jobMatch) => jobMatch.state !== MatchedApplicantState.BOOKMARK,
    );

    if (hasUnbookmarkedAlumni) {
      options.push({
        icon: <Box component="img" src={BookmarkIcon} />,
        disabled: false,
        className: "bookmark-option",
        label: t("menuOptions.bookmark.label"),
        handler: () => void handleAlumniBookmark(selectedJobMatches, true),
        hasDividerAbove: true,
      });
    }

    if (hasBookmarkedAlumni) {
      options.push({
        icon: <Box component="img" src={RemoveBookmarkIcon} />,
        disabled: false,
        className: "remove-bookmark-option",
        label: t("menuOptions.removeBookmark.label"),
        handler: () =>
          void handleAlumniRemoveBookmark(selectedJobMatches, true),
        hasDividerAbove: !hasUnbookmarkedAlumni,
      });
    }

    if (hasMatchedAlumni) {
      options.push({
        icon: <Box component="img" src={UserXIcon} />,
        disabled: false,
        className: "unmatch-option",
        label: t("menuOptions.unmatch.label"),
        handler: () =>
          void handleConfirmUnmatchModalOpen(selectedJobMatches, true),
        hasDividerAbove: true,
      });
    }
    return options;
  };

  const handleCloseConfirmUnmatchModal = () => {
    setConfirmUnmatchModalOpen(false);
  };

  const handleExport = async () => {
    setDownloadToast({ open: true, type: "loading" });
    try {
      const csvData = await getAllJobAlumniMatchesFile(orgId, jobId);
      const url = window.URL.createObjectURL(new Blob([csvData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `job-matches-${jobId}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setDownloadToast({ open: true, type: "failed" });
    }
  };

  const isSendingDisabled =
    job.status != JobStatusEnum.OPEN || numAlumSelected <= 0;

  const getTabData = (): ListJobAlumniMatchDTO => {
    switch (tabValue) {
      default:
        return { alumni_matches: jobMatches, total: totalAlumniJobMatches };
      case 1:
        return {
          alumni_matches: bookmarkedJobMatches,
          total: totalBookmarkedJobMatches,
        };
      case 2:
        return {
          alumni_matches: unmatchedJobMatches,
          total: totalUnmatchedJobMatches,
        };
    }
  };

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(0);
  };

  return (
    <Stack padding="2rem">
      <SuccessToastContainer containerId={toastContainerIds.success} />
      <InfoToastContainer containerId={toastContainerIds.info} />
      <Box paddingBottom="1rem">
        <Breadcrumbs />
      </Box>
      <Box
        borderTop={(theme) => `1px solid ${theme.palette.divider}`}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          paddingTop="2rem"
        >
          <FormattedText
            props={{
              value: job.name,
              format: Format.uppercaseFirstLetter,
            }}
            style={{
              fontWeight: 600,
              fontSize: "1.5rem",
              lineHeight: "2rem",
            }}
          />
          {job.requisition_id && (
            <FormattedText
              props={{
                value: `(${job.requisition_id})`,
                type: FormattedTextType.SECONDARY,
              }}
            />
          )}
          <Stack
            gap="0.5rem"
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.25rem"
              color={(theme) => theme.palette.grey[800]}
            >
              •
            </Typography>
            <FormattedText
              props={{
                value: job.status,
                format: (value: string) =>
                  value === JobStatusEnum.OPEN.toString()
                    ? t("header.status.open")
                    : value === JobStatusEnum.CLOSED.toString()
                    ? t("header.status.closed")
                    : capitalize(value),
              }}
              style={{
                color:
                  job.status === JobStatusEnum.OPEN
                    ? theme.palette.grey[800]
                    : theme.palette.grey[400],
              }}
            />
            {job.status === JobStatusEnum.CLOSED && job.boomerang_hire && (
              <Typography
                width="max-content"
                fontSize="0.75rem"
                lineHeight="1.125rem"
                padding="2px 6px"
                borderRadius="6px"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  background: theme.palette.primary.light,
                  border: `1px solid ${theme.palette.custom.purpleBorder}`,
                })}
              >
                {t("header.status.boomerangHire")}
              </Typography>
            )}
            {job.status === JobStatusEnum.CLOSED && !job?.boomerang_hire && (
              <Typography
                width="max-content"
                fontSize="0.75rem"
                lineHeight="1.125rem"
                padding="2px 6px"
                borderRadius="6px"
                sx={(theme) => ({
                  color: theme.palette.grey[600],
                  background: theme.palette.grey[50],
                  border: `1px solid ${theme.palette.grey[200]}`,
                })}
              >
                {t("header.status.externalHire")}
              </Typography>
            )}
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            component="button"
            onClick={handleViewJobPostingClicked}
            color={(theme) => theme.palette.grey[600]}
            fontWeight={600}
            fontSize="1.0rem"
            sx={{
              fontSize: "0.875rem",
              backgroundColor: "transparent",
              padding: "10px",
              borderRadius: "8px",
              cursor: viewJobDisabled ? "not-allowed" : "pointer",
              display: "inline-flex",
              alignItems: "center",
              gap: "0.75rem",
              border: "none",
              opacity: viewJobDisabled ? 0.5 : 1,
              "&:hover": {
                backgroundColor: viewJobDisabled
                  ? "transparent"
                  : theme.palette.grey[50],
              },
            }}
          >
            {t("viewJobPosting")}
            <Box
              component="img"
              src={externalLink}
              width="1.25rem"
              height="1.25rem"
              sx={{
                userSelect: "none",
                verticalAlign: "top",
              }}
            />
          </Typography>
        </Stack>
        <RolesViewOverview job={job} />
      </Box>
      <div>
        <Stack
          direction="row"
          marginTop="2rem"
          justifyContent="space-between"
          border="1px solid"
          padding="16px"
          borderColor={theme.palette.grey[200]}
          borderRadius="12px"
          sx={{ background: theme.palette.notification.info.backgroundColor }}
          alignItems="center"
        >
          <Stack id="criteria-information" direction="row" gap="0.5rem">
            <Typography
              color={theme.palette.grey[800]}
              fontSize="1rem"
              fontWeight="500"
              lineHeight="1.5rem"
              alignSelf="center"
            >
              {job.criteria_name === ""
                ? t("criteriaSection.defaultName")
                : job.criteria_name}
            </Typography>
            {isAutomationEnabled && (
              <Typography
                sx={{
                  padding: "0.125rem 0.375rem",
                  borderRadius: "0.375rem",
                  fontSize: "0.75rem",
                  lineHeight: "1.125rem",
                  backgroundColor: job.is_criteria_automated
                    ? theme.palette.tag.automated.background
                    : theme.palette.tag.manual.background,
                  color: job.is_criteria_automated
                    ? theme.palette.tag.automated.text
                    : theme.palette.tag.manual.text,
                  border: "1px solid",
                  borderColor: job.is_criteria_automated
                    ? theme.palette.tag.automated.border
                    : theme.palette.tag.manual.border,
                  height: "1.5rem",
                  alignSelf: "center",
                }}
              >
                {job.is_criteria_automated
                  ? t("criteriaSection.automated")
                  : t("criteriaSection.manual")}
              </Typography>
            )}
            <Tooltip
              title={t("criteriaSection.editTooltip")}
              componentsProps={{
                tooltip: {
                  sx: {
                    padding: "0.5rem 0.75rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5rem",
                    borderRadius: "0.5rem",
                    alignSelf: "stretch",
                  },
                },
              }}
            >
              <Box
                id="edit-criteria-button"
                component="img"
                src={editIcon}
                sx={{
                  cursor: "pointer",
                  width: "1.25rem",
                  height: "1.25rem",
                  alignSelf: "center",
                }}
                onClick={() =>
                  navigate("/settings/role-matching", {
                    state: {
                      criteriaId: job.criteria_id,
                    },
                  })
                }
              />
            </Tooltip>
          </Stack>
          <Stack id="matching-run-information" direction="row" gap="1rem">
            <Stack direction="row" gap="0.25rem">
              <Typography
                marginLeft="auto"
                color={theme.palette.grey[500]}
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="1.125rem"
              >
                {t("criteriaSection.lastMatchRun")}:
              </Typography>
              {job.time_of_last_matching_run ? (
                <FormattedText
                  props={{
                    value: job.time_of_last_matching_run,
                    format: Format.dateAsRelativeTime,
                    ellipsis: false,
                    type: FormattedTextType.TERTIARY,
                  }}
                />
              ) : (
                <Typography
                  color={theme.palette.grey[500]}
                  fontSize="0.75rem"
                  fontWeight="400"
                  lineHeight="1.125rem"
                >
                  {t("criteriaSection.na")}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" gap="0.25rem">
              <Typography
                color={theme.palette.grey[500]}
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="1.125rem"
              >
                {t("criteriaSection.nextMatchRun")}:
              </Typography>
              <FormattedText
                props={{
                  value: nextMatchingRun,
                  format: Format.dateAsRelativeTime,
                  ellipsis: false,
                  type: FormattedTextType.TERTIARY,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          id="search-sort-table"
          borderRadius="12px"
          border="1px solid"
          borderColor={theme.palette.grey[200]}
          marginTop="1rem"
        >
          <Stack
            direction="row"
            margin="1rem"
            gap="0.75rem"
            alignItems="center"
          >
            <SearchBar
              placeholder={t("searchPlaceholder")}
              onSearch={handleSearch}
              context={SearchbarContextType.ROLES_VIEW}
              jobId={jobId}
              searchTerm={searchTerm}
            />
            <Sort
              options={Object.values(sortOptions)}
              selectedOption={selectedOption}
              onSortChange={handleSortChange}
              trackAnalyticsEvent={trackSortAnalytics}
            />
            <Tooltip
              title={t("export.tooltip")}
              componentsProps={{
                tooltip: {
                  sx: {
                    padding: "0.5rem 0.75rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5rem",
                    borderRadius: "0.5rem",
                    alignSelf: "stretch",
                  },
                },
              }}
            >
              <IconButton
                disableRipple
                aria-haspopup="true"
                onClick={handleExport}
                className="export-button"
                disabled={downloadToast.open}
                sx={{
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[50],
                  },
                }}
              >
                <Box component="img" src={exportIcon} />
              </IconButton>
            </Tooltip>
            <Box marginLeft="auto">
              <Stack direction="row" spacing={1.25} alignItems="center">
                {numAlumSelected > 0 && (
                  <Typography
                    color={(theme) => theme.palette.grey[500]}
                    fontSize="0.875rem"
                    fontWeight="500"
                    lineHeight="1.25rem"
                  >
                    {numAlumSelected + " " + t("sendRole.selected")}
                  </Typography>
                )}
                {tabValue !== 2 ? (
                  <Stack direction="row" spacing={1.25} alignItems="center">
                    <Button
                      buttonProps={{
                        id: "send-role-button",
                        onClick: () =>
                          openSendEmailModal(
                            RoleViewEvent.ROLE_VIEW_SEND_ROLE,
                            EmailTemplateTypeEnum.SEND_ROLE,
                          ),
                        disabled: isSendingDisabled,
                        disableRipple: true,
                        sx: {
                          fontSize: "0.875rem",
                          boxShadow: "none",
                          height: "40px",
                          lineHeight: "40px",
                          backgroundColor: theme.palette.primary.main,
                          "&:hover": {
                            backgroundColor: theme.palette.custom.purpleHover,
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: theme.palette.custom.purplePressed,
                            boxShadow: "none",
                          },
                        },
                      }}
                    >
                      {t("sendRole.label")}
                    </Button>
                    <Box
                      border={`1px solid ${theme.palette.grey[200]}`}
                      borderRadius="0.5rem"
                    >
                      <Menu
                        id="bulk-options-menu"
                        items={generateBulkMenuOptions()}
                        disabled={isSendingDisabled}
                      />
                    </Box>
                  </Stack>
                ) : (
                  <Button
                    buttonProps={{
                      id: "restore-match-button",
                      onClick: () => handleRestoreClick(),
                      disableRipple: true,
                      disabled: selectedIds.size === 0,
                      sx: {
                        fontSize: "0.875rem",
                        boxShadow: "none",
                        height: "40px",
                        lineHeight: "40px",
                        color: theme.palette.grey[600],
                        border: `1px solid ${theme.palette.grey[300]}`,
                        borderRadius: "0.5rem",
                        backgroundColor: theme.palette.common.white,
                        "&:hover": {
                          background: theme.palette.grey[50],
                          boxShadow: "none",
                        },
                        "&:disabled": {
                          background: theme.palette.grey[50],
                          boxShadow: "none",
                        },
                        "&:active": {
                          color: theme.palette.grey[800],
                          boxShadow: "none",
                        },
                      },
                    }}
                  >
                    {t("menuOptions.restoreMatch.label")}
                  </Button>
                )}
              </Stack>
            </Box>
          </Stack>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            marginLeft="1rem"
            marginRight="1rem"
            marginBottom="0.5rem"
          >
            <Tabs
              textColor="inherit"
              value={tabValue}
              onChange={handleTabChange}
            >
              <Tab
                id="role-view-matches-tab"
                disableRipple
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <span>{t("tabs.matches.label")}</span>
                    <Box
                      sx={{
                        backgroundColor: theme.palette.grey[50],
                        fontSize: "0.75rem",
                        borderRadius: "1rem",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {totalAlumniJobMatches}
                    </Box>
                  </Stack>
                }
              />
              <Tab
                id="role-view-bookmarked-tab"
                disableRipple
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <span>{t("tabs.bookmarked.label")}</span>
                    <Box
                      sx={{
                        backgroundColor: theme.palette.grey[50],
                        fontSize: "0.75rem",
                        borderRadius: "1rem",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {totalBookmarkedJobMatches}
                    </Box>
                  </Stack>
                }
              />
              <Tab
                id="role-view-unmatched-tab"
                disableRipple
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <span>{t("tabs.unmatched.label")}</span>
                    <Box
                      sx={{
                        backgroundColor: theme.palette.grey[50],
                        fontSize: "0.75rem",
                        borderRadius: "1rem",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {totalUnmatchedJobMatches}
                    </Box>
                  </Stack>
                }
              />
            </Tabs>
          </Box>
          {isLoadingJobMatches ||
          isLoadingUnmatchedJobMatches ||
          isLoadingBookmarkedJobMatches ? (
            <Loading />
          ) : tabValue === 0 && jobMatches.length === 0 ? (
            <Stack paddingBottom="10rem" paddingTop="1rem">
              <EmptyList
                icon={Users}
                iconAlt="user icon"
                title={t("tabs.matches.emptyTitle")}
                message={t("tabs.matches.emptySubtitle")}
              />
            </Stack>
          ) : tabValue === 1 && bookmarkedJobMatches.length === 0 ? (
            <Stack paddingBottom="10rem" paddingTop="1rem">
              <EmptyList
                icon={StarIcon}
                iconAlt="star icon"
                title={t("tabs.bookmarked.emptyTitle")}
                message={
                  <Trans
                    ns={Translation.rolesView}
                    i18nKey="tabs.bookmarked.emptySubtitle"
                    components={{
                      icon: (
                        <img
                          src={BookmarkDark}
                          alt="bookmark"
                          style={{
                            width: "16px",
                            height: "16px",
                            display: "inline",
                            verticalAlign: "middle",
                          }}
                        />
                      ),
                    }}
                  />
                }
              />
            </Stack>
          ) : tabValue === 2 && unmatchedJobMatches.length === 0 ? (
            <Stack paddingBottom="10rem" paddingTop="1rem">
              <EmptyList
                icon={UserXLargeIcon}
                iconAlt="user x icon"
                title={t("tabs.unmatched.emptyTitle")}
                message={
                  <Trans
                    ns={Translation.rolesView}
                    i18nKey="tabs.unmatched.emptySubtitle"
                    components={{
                      icon: (
                        <img
                          src={DotsVertical}
                          alt="dots"
                          style={{
                            width: "16px",
                            height: "16px",
                            display: "inline",
                            verticalAlign: "middle",
                          }}
                        />
                      ),
                    }}
                  />
                }
              />
            </Stack>
          ) : (
            <RolesViewTable
              jobMatches={getTabData().alumni_matches}
              page={page}
              pageSize={PAGE_SIZE}
              totalRows={getTabData().total}
              onPageChange={setPage}
              onCheckboxClick={setNumAlumSelected}
              isRoleClosed={job.status === JobStatusEnum.CLOSED}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              onClick={onRowClick}
              onAlumniSendMessage={handleAlumniSendMessage}
              onAlumniSendRole={handleAlumniSendRole}
              onAlumniBookmark={(jamDTO) => void handleAlumniBookmark([jamDTO])}
              onAlumniRemoveBookmark={(jamDTO) =>
                void handleAlumniRemoveBookmark([jamDTO])
              }
              onAlumniMatch={(jamDTO) => void handleAlumniMatch([jamDTO])}
              onAlumniRemoveMatch={(jamDTO) =>
                void handleConfirmUnmatchModalOpen([jamDTO])
              }
              onAlumniRequestFeedback={handleAlumniRequestFeedback}
              tabValue={tabValue}
              setSingleEmailReceiver={setSingleEmailReceiver}
            />
          )}
        </Stack>
      </div>
      <CenterModal
        open={
          sendEmailModalOpen != null &&
          sendEmailModalOpen !== EmailTemplateTypeEnum.JOB_MATCH_APPROVAL
        }
      >
        <SendEmailModal
          searchAllAlumni={false}
          onSubmit={handleSendEmail}
          onClose={() => setSendEmailModalOpen(null)}
          initialJob={
            sendEmailModalOpen === EmailTemplateTypeEnum.SEND_ROLE ? job : null
          }
          selectedIds={
            singleEmailReceiver
              ? new Set([singleEmailReceiver.id])
              : selectedIds
          }
          emailType={sendEmailModalOpen!}
          receivers={
            singleEmailReceiver
              ? [singleEmailReceiver]
              : jobMatches.map(
                  (match) =>
                    ({
                      id: match.applicant_id,
                      firstName: match.first_name,
                      lastName: match.last_name,
                      email: match.email,
                      status: match.status,
                      isSubscribed: match.is_subscribed,
                    }) as Receiver,
                )
          }
        />
      </CenterModal>
      <CenterModal
        open={sendEmailModalOpen === EmailTemplateTypeEnum.JOB_MATCH_APPROVAL}
      >
        <RequestFeedbackModal
          onSubmit={handleSendEmail}
          onClose={() => setSendEmailModalOpen(null)}
          job={job}
          selectedIds={
            singleEmailReceiver
              ? new Set([singleEmailReceiver.id])
              : selectedIds
          }
          receivers={
            singleEmailReceiver
              ? [singleEmailReceiver]
              : jobMatches.map(
                  (match) =>
                    ({
                      id: match.applicant_id,
                      firstName: match.first_name,
                      lastName: match.last_name,
                      email: match.email,
                      status: match.status,
                    }) as Receiver,
                )
          }
        />
      </CenterModal>
      <ConfirmActionModal
        open={confirmUnmatchModalOpen}
        onClose={() => handleCloseConfirmUnmatchModal()}
        primaryButtonHandler={() => void handleAlumniUnmatch(unmatchPayload)}
        secondaryButtonHandler={() => setConfirmUnmatchModalOpen(false)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t("unmatchModal.heading")}
        subHeading={t("unmatchModal.body")}
        primaryButtonText={t("unmatchModal.confirmUnmatch")}
        secondaryButtonText={t("unmatchModal.cancel")}
      ></ConfirmActionModal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={downloadToast.open}
        autoHideDuration={3000}
        onClose={() => setDownloadToast({ open: false, type: "loading" })}
      >
        <DataExportToast
          type={downloadToast.type}
          closeToast={() => setDownloadToast({ open: false, type: "loading" })}
        />
      </Snackbar>
    </Stack>
  );
}

const DataExportToast = React.forwardRef(
  (
    props: { 
      closeToast: () => void,
      type: "loading" | "failed"
    } & React.HTMLAttributes<HTMLDivElement>,
    ref: React.Ref<unknown>,
  ) => {
    const { t } = useTranslation(Translation.rolesView);
    const { closeToast, type, ...rest } = props;

    const styles = {
      loading: {
        color: theme.palette.primaryColor[600],
        bgcolor: theme.palette.primaryColor[25],
        borderColor: theme.palette.primaryColor[300],
      },
      failed: {
        color: theme.palette.errorColor[700],
        bgcolor: theme.palette.errorColor[25],
        borderColor: theme.palette.errorColor[300],
      },
    }

    return (
      <Fade {...rest} ref={ref}>
        <Stack
          sx={{
            flexDirection: "row",
            padding: "16px",
            gap: "16px",
            alignItems: "flex-start",
            bgcolor: styles[type].bgcolor,
            border: `1px solid ${styles[type].borderColor}`,
            borderRadius: "4px",
            position: "relative",
            width: "750px",
          }}
        >
          {/* Info Icon */}
          <InfoOutlinedIcon
            sx={{ color: styles[type].color, mt: "3px" }}
          />

          {/* Text Content */}
          <Stack color={styles[type].color} gap="4px">
            <Typography fontSize="14px" lineHeight="20px" fontWeight={600}>
              {t(`export.${type}.title`)}
            </Typography>
            <Typography fontSize="14px" lineHeight="20px" fontWeight={400}>
              {t(`export.${type}.subtitle`)}
            </Typography>
          </Stack>

          {/* Close Button */}
          <IconButton
            size="small"
            disableRipple
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: styles[type].color,
            }}
            onClick={closeToast}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Fade>
    );
  },
);
